@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.animHover:after {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  height: 2px;
  width: 0;
  background-color: #000;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.animHover:hover:after {
  width: 100%;
}

.animHovered:after {
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  height: 2px;
  width: 0;
  background-color: #000;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.animHovered:hover:after {
  width: 100%;
}

.animHoverFooter:after {
  content: "";
  display: block;
  position: absolute;
  top: 33px;
  height: 2px;
  width: 0;
  background-color: #fff;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.animHoverFooter:hover:after {
  width: 100%;
}
